import React from "react"

const IndustryCard = ( cardData ) => (
	<div className="industry-cards-wrap container">
		{cardData.cardData.map(industry => (
			<div className="industry-cards__item" key={industry.id}>
				<img src={industry.icon.url} alt={industry.icon.fileName} />
				<h4 className="industry-heading">{industry.heading}</h4>
			</div>
		))}
	</div>
)
export default IndustryCard
